* {
  margin: 0;
}

.app__tool {
  display: flex;
  justify-content: space-between;
}

.app__body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 105px;
}

.app__left {
  flex: 0.85;
}

.app__right {
  margin-bottom: 16px;
}

.app__right hr {
  margin: 10px;
}

.app__right .MuiCardContent-root {
  display: flex;
  flex-direction: column;
}

.app__line {
  flex-grow: 1;
  height: 220px;
}

.app__stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 900px) {
  .app__body {
    flex-direction: column;
  }
}

@media (max-width: 468px) {
  .app__body {
    margin-top: 150px;
  }
}

.logo {
  height: 60px;
  margin: 10px 0;
}

.app__footer {
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 0 10px;
  background-color: #f5f5f5;
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 18px;
  color: #707070;
}
.app__footer a {
  text-decoration: none;
  color: #cc1034;
}
.app__footer img {
  height: 25px;
  margin-left: 4px;
}
